<template>
  <div class="common-table">
    <el-table
      v-loading="config.loading"
      :data="tableData"
      highlight-current-row
      border
      stripe
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column label="序号" prop="id" width="80"></el-table-column>
      <el-table-column label="项目或工地" prop="xiangmuorgongdi" width="160"></el-table-column>
      <el-table-column label="回库库房" prop="cangku_name" width="100"></el-table-column>
      <el-table-column label="材料名称" prop="cailiao_name" width="140"></el-table-column>
      <el-table-column label="材料规格" prop="cailiaoguige_name" width="140"></el-table-column>
      <el-table-column label="回库数量" prop="amount" width="80"></el-table-column>
      <el-table-column label="回库时间" prop="date" width="100" sortable></el-table-column>
      <el-table-column align="center" label="回库单据" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="handlePhotos(scope.row)">回库单据</el-button>
        </template>
      </el-table-column>
      <el-table-column label="备注信息" prop="remark" width="250"></el-table-column>
      <el-table-column label="添加" prop="added" width="130"></el-table-column>
      <el-table-column align="center" prop="wuzi_shenhe_person" label="物资审核" width="80"> </el-table-column>
      <el-table-column label="管理" width="200">
        <template slot-scope="scope">
          <el-button type="warning" size="mini" @click="handleWuzi_shenhe(scope.row)" v-if="!scope.row.wuzi_shenhe && userType.code === '4'"
            >物资审核</el-button
          >
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteTableRow(scope.$index, scope.row)" v-if="canDelete(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      :page-size="pagesize"
      layout="prev, pager, next"
      :total="config.total"
      :current-page.sync="config.page"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  name: 'Table',
  props: {
    tableData: Array,
    config: Object,
    isShowHeyueField: Boolean,
    userType: Object
  },
  data() {
    return {
      pagesize: 20,
      TableHeaderRowStyle() {
        return 'height:35px'
      },
      TableHeaderCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
      },
      TableRowStyle() {
        return 'height:35px'
      },
      TableCellStyle() {
        return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
      }
    }
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.kufanghuiku_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },
    deleteTableRow(index, row) {
      this.$confirm('此操作将删除该回库信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/deleteHuikuxinxi/?id=' + row.id).then(res => {
          console.log(res.data)
          if (res.data === '删除成功！') {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.tableData.splice(index, 1)
          } else {
            this.$message({
              message: '删除失败，此信息不存在或已被删除'
            })
          }
        })
      })
    },
    //显示回库图片
    handlePhotos(row) {
      this.$emit('showPhotos', row)
    },
    //物资审核
    handleWuzi_shenhe(row) {
      this.$emit('Wuzi_shenhe', row)
    }
  }
}
</script>

<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
