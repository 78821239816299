<template>
  <div>
    <el-dialog
      width="900px"
      :visible.sync="isShow"
      title="添加新的回库"
      :close-on-click-modal="false"
    >
      <huiku-from
        ref="addhuikuForm"
        @submitForm="submitForm"
        @resetForm="resetHuikuForm"
      ></huiku-from>
    </el-dialog>
    <!-- 回库显示照片 -->
    <el-dialog
      width="1000px"
      :visible.sync="huikuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in huikuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="huikuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>

    <el-container>
      <el-header style="height:30px">
        <div class="left">
          <el-button
            type="primary"
            v-if="usertype.code === '6' || usertype.code === '4'"
            @click="isShow = true"
            >添加新的回库
          </el-button>
          <el-button type="primary"> 导出数据 </el-button>
          <el-radio-group v-model="huikuxinxiview" @change="setHuikuxinxiView">
            <el-radio :label="1">按合同显示</el-radio>
            <el-radio :label="2">按库房显示</el-radio>
          </el-radio-group>
        </div>
        <div class="right">
          <el-input
            placeholder="项目 | 工地 | 库房 | 材料"
            v-model="keyword"
            @keyup.enter.native="getTableData()"
          >
            <el-button slot="append" @click="getTableData()"> 查询 </el-button>
          </el-input>
        </div>
      </el-header>
      <el-main>
        <huiku-table
          :tableData="tableData"
          :config="config"
          :userType="usertype"
          @Wuzi_shenhe="Wuzi_shenhe"
          @showPhotos="showPhotosinTableHuiku"
          @changePage="getTableData"
        ></huiku-table>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import huikuFrom from './huikuForm'
import huikuTable from './huikuTable'
import utils from '../../../assets/js/utils'
export default {
  name: 'mainwuziHuikuManage',
  components: {
    huikuFrom,
    huikuTable
  },
  data() {
    return {
      isShow: false,
      keyword: '',

      tableData: [],
      huikuFormData: {},
      huikuPhotos_isShow: false,
      huikuphotosurls: [],
      company: utils.getCompanyCookie(),
      huikuxinxiview: 1,
      config: {
        page: 1,
        total: 0,
        loading: false
      }
    }
  },
  methods: {
    getTableData() {
      this.config.loading = true
      const api = '/getHuikuxinxis/'
      this.$axios
        .get(api, {
          params: {
            company: this.company, //当前用户所属分公司编号code
            huikuxinxiview: this.huikuxinxiview, //回库信息查询方式
            page: this.config.page,
            keyword: this.keyword
          }
        })
        .then(res => {
          console.log(res)
          if (res.data.code === '1000') {
            this.config.total = res.data.totalcount
            console.log('this.config.total', this.config.total)
            this.tableData = res.data.data.map(item => {
              item.xiangmuorgongdi =
                item.contract_id + '#' + item.contract_project
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              return item
            })
          } else {
            console.log('没有回库信息！')
          }
          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //在table中显示出库图片
    showPhotosinTableHuiku(row) {
      this.huikuphotosurls = []
      let photos = row.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地

        this.huikuphotosurls.push(url)
      }
      console.log(this.huikuphotosurls)
      this.huikuPhotos_isShow = true
    },

    //提交回库表单
    submitForm(formData) {
      this.huikuFormData = formData
      console.log('huikuFormData........:', this.huikuFormData)
      this.huikuFormData.added_person = this.user_realname

      this.$axios.post('/addHuikuxinxi/', this.huikuFormData).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '增加回库信息成功!',
            duration: 2000
          })
          this.resetHuikuForm()
          this.getTableData()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg,
            duration: 2000
          })
        }
      })
    },
    resetHuikuForm() {
      this.isShow = false
      this.huikuFormData = {}
    },
    //物资审核
    Wuzi_shenhe(row) {
      this.$confirm('确定通过审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const api = '/huikuWuzishenhe/'
        this.$axios
          .get(api, {
            params: {
              id: row.id,
              wuzi_shenhe_person: this.user_realname,
              wuzi_shenhe_time: utils.getDateTime()
            }
          })
          .then(res => {
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '审核成功！'
              })
              this.getTableData()
            } else {
              this.$message({
                message: '审核失败'
              })
            }
          })
      })
    },
    //设置回库信息显示方式
    setHuikuxinxiView(value) {
      this.huikuxinxiview = value
      console.log('this.huikuxinxiview:', this.huikuxinxiview)
      this.getTableData()
    }
  },
  created() {
    let user = utils.getUserCookie()
    this.usertype = user.user_type
    this.user_realname = user.name
    console.log('usertype:', this.usertype)
    console.log('user_realname:', this.user_realname)
    this.config.loading = true
    this.getTableData('')
  }
}
</script>

<style scoped>
.inline {
  display: inline;
}

.right {
  float: right;
}
.left {
  float: left;
}
</style>
